/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CurrencyHistoricalRatesDto,
  CurrencyRatesRetrieveCodeEnum,
  CurrencyRatesRetrieveParams,
  CurrencyRatesRetrieveTypeEnum,
} from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class CurrencyRates<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
 * No description
 *
 * @tags currency-rates
 * @name CurrencyRatesRetrieve
 * @request GET:/api/v1/currency-rates/
 * @secure
 * @response `200` `CurrencyHistoricalRatesDto`
 * @response `400` `{
    type: CurrencyRatesRetrieveTypeEnum,
    error: {
    code: CurrencyRatesRetrieveCodeEnum,
    message: string,

},

}`
 */
  currencyRatesRetrieve = (
    query: CurrencyRatesRetrieveParams,
    params: RequestParams = {},
  ) =>
    this.http.request<
      CurrencyHistoricalRatesDto,
      {
        type: CurrencyRatesRetrieveTypeEnum;
        error: {
          code: CurrencyRatesRetrieveCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/currency-rates/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
