import revive_payload_client_C8ka8DPfJA from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.21.3_typescript@5.6.2_vite@5.4.6_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_kH2jDmnvRS from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.21.3_typescript@5.6.2_vite@5.4.6_vue-tsc@1.8.27/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_s5gfBfmiJr from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.21.3_typescript@5.6.2_vite@5.4.6_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_0dOysccfox from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.21.3_typescript@5.6.2_vite@5.4.6_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_rRNBoFxbAA from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.21.3_typescript@5.6.2_vite@5.4.6_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_UaqhmsyCbx from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.21.3_typescript@5.6.2_vite@5.4.6_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Q1gffMi4yA from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.21.3_typescript@5.6.2_vite@5.4.6_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_xG6Amgvek3 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.21.3_typescript@5.6.2_vite@5.4.6_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_Y5fPRaJ2wn from "/opt/buildhome/repo/node_modules/.pnpm/@sentry+nuxt@8.30.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+instru_7stw4gxvcjaipk2guqbs4htuom/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/buildhome/repo/.nuxt/sentry-client-config.mjs";
import plugin_6Ph1iQzNHk from "/opt/buildhome/repo/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import primevue_7rYYRZQLyx from "/opt/buildhome/repo/plugins/primevue.ts";
import vue_query_wrmMkNpEpe from "/opt/buildhome/repo/plugins/vue-query.ts";
export default [
  revive_payload_client_C8ka8DPfJA,
  unhead_kH2jDmnvRS,
  router_s5gfBfmiJr,
  payload_client_0dOysccfox,
  navigation_repaint_client_rRNBoFxbAA,
  check_outdated_build_client_UaqhmsyCbx,
  chunk_reload_client_Q1gffMi4yA,
  components_plugin_KR1HBZs4kY,
  prefetch_client_xG6Amgvek3,
  sentry_client_Y5fPRaJ2wn,
  sentry_client_config_o34nk2sJbg,
  plugin_6Ph1iQzNHk,
  primevue_7rYYRZQLyx,
  vue_query_wrmMkNpEpe
]