/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Organization,
  OrganizationClient,
  OrganizationClientUpdateRequest,
  OrganizationCreateRequest,
  OrganizationDetailed,
  OrganizationDetailedRequest,
  OrganizationsCreateCodeEnum,
  OrganizationsCreateTypeEnum,
  OrganizationsListParams,
  OrganizationsPartialUpdateCodeEnum,
  OrganizationsPartialUpdateTypeEnum,
  OrganizationsUpdateCodeEnum,
  OrganizationsUpdateTypeEnum,
  PatchedOrganizationClientUpdateRequest,
  PatchedOrganizationDetailedRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Organizations<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Return detail-serialized created instance
   *
   * @tags organizations
   * @name OrganizationsList
   * @request GET:/api/v1/organizations/
   * @secure
   * @response `200` `(Organization)[]`
   */
  organizationsList = (
    query: OrganizationsListParams,
    params: RequestParams = {},
  ) =>
    this.http.request<Organization[], any>({
      path: `/api/v1/organizations/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
 * @description Return detail-serialized created instance
 *
 * @tags organizations
 * @name OrganizationsCreate
 * @request POST:/api/v1/organizations/
 * @secure
 * @response `201` `OrganizationDetailed`
 * @response `400` `{
    type: OrganizationsCreateTypeEnum,
    error: {
    code: OrganizationsCreateCodeEnum,
    message: string,

},

}`
 */
  organizationsCreate = (
    data: OrganizationCreateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      OrganizationDetailed,
      {
        type: OrganizationsCreateTypeEnum;
        error: {
          code: OrganizationsCreateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/organizations/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags organizations
   * @name OrganizationsClientsRetrieve
   * @request GET:/api/v1/organizations/{organizationPk}/clients/{id}/
   * @secure
   * @response `200` `OrganizationClient`
   */
  organizationsClientsRetrieve = (
    id: string,
    organizationPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<OrganizationClient, any>({
      path: `/api/v1/organizations/${organizationPk}/clients/${id}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags organizations
   * @name OrganizationsClientsUpdate
   * @request PUT:/api/v1/organizations/{organizationPk}/clients/{id}/
   * @secure
   * @response `200` `OrganizationClient`
   */
  organizationsClientsUpdate = (
    id: string,
    organizationPk: string,
    data: OrganizationClientUpdateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<OrganizationClient, any>({
      path: `/api/v1/organizations/${organizationPk}/clients/${id}/`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags organizations
   * @name OrganizationsClientsPartialUpdate
   * @request PATCH:/api/v1/organizations/{organizationPk}/clients/{id}/
   * @secure
   * @response `200` `OrganizationClient`
   */
  organizationsClientsPartialUpdate = (
    id: string,
    organizationPk: string,
    data: PatchedOrganizationClientUpdateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<OrganizationClient, any>({
      path: `/api/v1/organizations/${organizationPk}/clients/${id}/`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags organizations
   * @name OrganizationsRetrieve
   * @request GET:/api/v1/organizations/{id}/
   * @secure
   * @response `200` `OrganizationDetailed`
   */
  organizationsRetrieve = (id: string, params: RequestParams = {}) =>
    this.http.request<OrganizationDetailed, any>({
      path: `/api/v1/organizations/${id}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
 * @description Return detail-serialized created instance
 *
 * @tags organizations
 * @name OrganizationsUpdate
 * @request PUT:/api/v1/organizations/{id}/
 * @secure
 * @response `200` `OrganizationDetailed`
 * @response `400` `{
    type: OrganizationsUpdateTypeEnum,
    error: {
    code: OrganizationsUpdateCodeEnum,
    message: string,

},

}`
 */
  organizationsUpdate = (
    id: string,
    data: OrganizationDetailedRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      OrganizationDetailed,
      {
        type: OrganizationsUpdateTypeEnum;
        error: {
          code: OrganizationsUpdateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/organizations/${id}/`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
 * @description Return detail-serialized created instance
 *
 * @tags organizations
 * @name OrganizationsPartialUpdate
 * @request PATCH:/api/v1/organizations/{id}/
 * @secure
 * @response `200` `OrganizationDetailed`
 * @response `400` `{
    type: OrganizationsPartialUpdateTypeEnum,
    error: {
    code: OrganizationsPartialUpdateCodeEnum,
    message: string,

},

}`
 */
  organizationsPartialUpdate = (
    id: string,
    data: PatchedOrganizationDetailedRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      OrganizationDetailed,
      {
        type: OrganizationsPartialUpdateTypeEnum;
        error: {
          code: OrganizationsPartialUpdateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/organizations/${id}/`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
