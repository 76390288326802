/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  NotificationsSnoozeCreateCodeEnum,
  NotificationsSnoozeCreateTypeEnum,
  SiteNotification,
  SiteNotificationCount,
  SiteNotificationMarkAllViewed,
  SiteNotificationSnoozeRequest,
  SiteNotificationSnoozeResponse,
  SiteNotificationUpdateRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Notifications<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags notifications
   * @name NotificationsList
   * @request GET:/api/v1/notifications/
   * @secure
   * @response `200` `(SiteNotification)[]`
   */
  notificationsList = (params: RequestParams = {}) =>
    this.http.request<SiteNotification[], any>({
      path: `/api/v1/notifications/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags notifications
   * @name NotificationsUpdate
   * @request PUT:/api/v1/notifications/{id}/
   * @secure
   * @response `200` `SiteNotification`
   */
  notificationsUpdate = (
    id: string,
    data: SiteNotificationUpdateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<SiteNotification, any>({
      path: `/api/v1/notifications/${id}/`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
 * No description
 *
 * @tags notifications
 * @name NotificationsSnoozeCreate
 * @request POST:/api/v1/notifications/{id}/snooze/
 * @secure
 * @response `200` `SiteNotificationSnoozeResponse`
 * @response `400` `{
    type: NotificationsSnoozeCreateTypeEnum,
    error: {
    code: NotificationsSnoozeCreateCodeEnum,
    message: string,

},

}`
 */
  notificationsSnoozeCreate = (
    id: string,
    data: SiteNotificationSnoozeRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      SiteNotificationSnoozeResponse,
      {
        type: NotificationsSnoozeCreateTypeEnum;
        error: {
          code: NotificationsSnoozeCreateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/notifications/${id}/snooze/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags notifications
   * @name NotificationsCountRetrieve
   * @request GET:/api/v1/notifications/count/
   * @secure
   * @response `200` `SiteNotificationCount`
   */
  notificationsCountRetrieve = (params: RequestParams = {}) =>
    this.http.request<SiteNotificationCount, any>({
      path: `/api/v1/notifications/count/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags notifications
   * @name NotificationsMarkAllViewedCreate
   * @request POST:/api/v1/notifications/mark-all-viewed/
   * @secure
   * @response `200` `SiteNotificationMarkAllViewed`
   */
  notificationsMarkAllViewedCreate = (params: RequestParams = {}) =>
    this.http.request<SiteNotificationMarkAllViewed, any>({
      path: `/api/v1/notifications/mark-all-viewed/`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params,
    });
}
