import { QueryClient } from '@tanstack/vue-query';
import { useStorage } from '@vueuse/core';
import { invalidateUser } from './query/users';
import { authApi } from '~/utils/api';

export const useAuth = () => {
  // This method and localStorage key are hardcoded on the backend for PDF generation. Do not change without proper reason.
  const state = useStorage<{ token: string | undefined }>('auth', {
    token: undefined,
  });

  const token = computed(() => state.value.token);
  const isAuthorized = computed(() => token.value !== undefined);
  const router = useRouter();

  const loginWithCredentials = async (
    queryClient: QueryClient,
    data: { username: string; password: string },
  ) => {
    const { username, password } = data;

    const { token } = await authApi.authTokenCreate({
      username,
      password,
    });

    state.value.token = token;
    invalidateUser(queryClient);
  };

  const logout = () => {
    state.value.token = undefined;
    router.push({ name: 'login' });
  };

  return { token, isAuthorized, logout, loginWithCredentials };
};
