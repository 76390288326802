import { useQueryClient } from '@tanstack/vue-query';
import { useKillSwitchQuery, invalidateKillSwitchQuery } from './query/users';
import { useInterval } from '@vueuse/core';
import { useRefHistory } from '@vueuse/core';

export const useKillSwitch = () => {
  const { data } = useKillSwitchQuery();
  const queryClient = useQueryClient();

  const isKillSwitchEnabled = ref<boolean>();
  const { history } = useRefHistory(isKillSwitchEnabled);
  watch(
    () => data.value,
    () => {
      isKillSwitchEnabled.value = data.value?.isEnabled;
    },
  );

  const interval = useInterval(5_000);
  watch(
    () => interval.value,
    () => {
      // Disable this feature on dev because of request spam
      if (import.meta.env.MODE !== 'development') {
        invalidateKillSwitchQuery(queryClient);
      }
    },
  );

  watch(
    () => history.value,
    () => {
      if (
        history.value[1].snapshot !== undefined &&
        history.value[0].snapshot !== history.value[1].snapshot
      ) {
        window.location.reload();
      }
    },
  );
};
