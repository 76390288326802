import { useRuntimeConfig } from '#app';

export default defineNuxtRouteMiddleware(async () => {
  const config = useRuntimeConfig();

  async function enableMocking() {
    const { worker } = await import('@/mocks/backend');
    return worker.start();
  }

  if (config.public.MOCK_BACKEND === 'true') {
    const isRegistred = (await navigator.serviceWorker.getRegistrations())
      .map((worker) => worker.scope)
      .some((scope) => scope === location.origin);
    if (!isRegistred) {
      await enableMocking();
    }
  }
});
