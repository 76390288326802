import {
  CountryEnum,
  OrderAgent,
  OrderAgentCreateRequest,
  OrderDocumentTag,
  OrderLabel,
  AttachedDocumentTag,
  TermsOfDeliveryEnum,
  BlankEnum,
} from './api/data-contracts';

export type Chip = OrderDocumentTag | OrderLabel | AttachedDocumentTag;

export type SelectOption = {
  label: string;
  value: string;
};

export type FormErrors = Record<string, string[]>;
export type SerivceError = {
  serivceError: string;
  type: 'serivceError';
  error: {
    code: string;
    message: string;
  };
};
export type UnifiedErrors = FormErrors;

export type EditableOrderAgent = {
  id?: OrderAgent['id'];
} & OrderAgentCreateRequest;

export type InvoiceOrderDraft = {
  orderRef: string;
  destinationsCodes: (CountryEnum | null)[];
  consignor: string;
  consignorName: string;
  consignorAddress: string;
  consignee: string;
  consigneeName: string;
  consigneeAddress: string;
  containerIds: string[];
  blNumbers: string[];
  hawbNumbers: string[];
  mawbNumbers: string[];
  vehicleIds: string[];
  termsOfDelivery: TermsOfDeliveryEnum | BlankEnum;
  termsOfDeliveryCity: string;
};

export enum OrderListMode {
  Cards = 'cards',
  List = 'list',
}
