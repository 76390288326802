import dayjs from '~/utils/dayjs';

export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';

// This util is used to save dates on backend
// Backend uses full ISO string format with Date + Time,
// and short ISO-like format for dates without time

export const toBackendDate = (
  date: Parameters<typeof dayjs>[0],
  time = false,
) =>
  time ? dayjs(date).toISOString() : dayjs(date).format(BACKEND_DATE_FORMAT);
