/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  OrderTotalNetDetailed,
  OrdersTotalNetListParams,
  PaginatedOrderTotalNetListList,
} from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class OrdersTotalNet<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags orders-total-net
   * @name OrdersTotalNetList
   * @request GET:/api/v1/orders-total-net/
   * @secure
   * @response `200` `PaginatedOrderTotalNetListList`
   */
  ordersTotalNetList = (
    query: OrdersTotalNetListParams,
    params: RequestParams = {},
  ) =>
    this.http.request<PaginatedOrderTotalNetListList, any>({
      path: `/api/v1/orders-total-net/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags orders-total-net
   * @name OrdersTotalNetRetrieve
   * @request GET:/api/v1/orders-total-net/{id}/
   * @secure
   * @response `200` `OrderTotalNetDetailed`
   */
  ordersTotalNetRetrieve = (id: string, params: RequestParams = {}) =>
    this.http.request<OrderTotalNetDetailed, any>({
      path: `/api/v1/orders-total-net/${id}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
