/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AttachedDocumentTagSetRequest,
  Insurance,
  InsuranceCreateRequest,
  InsuranceDocument,
  InsuranceDocumentRequest,
  InsuranceRequest,
  InvoiceDocumentCreate,
  InvoiceDocumentCreateRequest,
  InvoiceListForOrder,
  Label,
  OrderAgent,
  OrderAgentCreateRequest,
  OrderArchiveRequest,
  OrderArchiveResponse,
  OrderCreateRequest,
  OrderDetailed,
  OrderDocument,
  OrderDocumentRequest,
  OrderDocumentTag,
  OrderLabelSetRequest,
  OrderManagerSetRequest,
  OrderShipmentReference,
  OrderShipmentReferenceRequest,
  OrderShipmentUnit,
  OrderShipmentUnitCreateRequest,
  OrderTask,
  OrderUpdateRequest,
  OrdersAgentsCreateCodeEnum,
  OrdersAgentsCreateTypeEnum,
  OrdersAgentsPartialUpdateCodeEnum,
  OrdersAgentsPartialUpdateTypeEnum,
  OrdersAgentsUpdateCodeEnum,
  OrdersAgentsUpdateTypeEnum,
  OrdersCreateCodeEnum,
  OrdersCreateTypeEnum,
  OrdersDestroyCodeEnum,
  OrdersDestroyTypeEnum,
  OrdersInsurancesDocumentCreateCodeEnum,
  OrdersInsurancesDocumentCreateTypeEnum,
  OrdersInsurancesDocumentDestroyCodeEnum,
  OrdersInsurancesDocumentDestroyTypeEnum,
  OrdersInsurancesPartialUpdateCodeEnum,
  OrdersInsurancesPartialUpdateTypeEnum,
  OrdersInsurancesUpdateCodeEnum,
  OrdersInsurancesUpdateTypeEnum,
  OrdersInvoicesForPurchaseCreateCodeEnum,
  OrdersInvoicesForPurchaseCreateTypeEnum,
  OrdersInvoicesForPurchaseDocumentCreateCodeEnum,
  OrdersInvoicesForPurchaseDocumentCreateTypeEnum,
  OrdersInvoicesForPurchaseDocumentDestroyCodeEnum,
  OrdersInvoicesForPurchaseDocumentDestroyTypeEnum,
  OrdersInvoicesForPurchasePartialUpdateCodeEnum,
  OrdersInvoicesForPurchasePartialUpdateTypeEnum,
  OrdersInvoicesForPurchaseUpdateCodeEnum,
  OrdersInvoicesForPurchaseUpdateTypeEnum,
  OrdersInvoicesForSaleBlankPartialUpdateCodeEnum,
  OrdersInvoicesForSaleBlankPartialUpdateTypeEnum,
  OrdersInvoicesForSaleBlankResetCreateCodeEnum,
  OrdersInvoicesForSaleBlankResetCreateTypeEnum,
  OrdersInvoicesForSaleCloseCreateCodeEnum,
  OrdersInvoicesForSaleCloseCreateTypeEnum,
  OrdersInvoicesForSaleCreateCodeEnum,
  OrdersInvoicesForSaleCreateTypeEnum,
  OrdersInvoicesForSaleGeneratePdfCreateCodeEnum,
  OrdersInvoicesForSaleGeneratePdfCreateTypeEnum,
  OrdersInvoicesForSalePartialUpdateCodeEnum,
  OrdersInvoicesForSalePartialUpdateTypeEnum,
  OrdersInvoicesForSaleSetOrderInformationCreateCodeEnum,
  OrdersInvoicesForSaleSetOrderInformationCreateTypeEnum,
  OrdersInvoicesForSaleShipmentUnitsCreateCodeEnum,
  OrdersInvoicesForSaleShipmentUnitsCreateTypeEnum,
  OrdersInvoicesForSaleShipmentUnitsDestroyCodeEnum,
  OrdersInvoicesForSaleShipmentUnitsDestroyTypeEnum,
  OrdersInvoicesForSaleUpdateCodeEnum,
  OrdersInvoicesForSaleUpdateTypeEnum,
  OrdersLabelsUpdateCodeEnum,
  OrdersLabelsUpdateTypeEnum,
  OrdersListParams,
  OrdersManagersUpdateCodeEnum,
  OrdersManagersUpdateTypeEnum,
  OrdersPartialUpdateCodeEnum,
  OrdersPartialUpdateTypeEnum,
  OrdersSetArchivedCreateCodeEnum,
  OrdersSetArchivedCreateTypeEnum,
  OrdersShipmentUnitsDestroyCodeEnum,
  OrdersShipmentUnitsDestroyTypeEnum,
  OrdersUpdateCodeEnum,
  OrdersUpdateTypeEnum,
  PaginatedOrderListList,
  PatchedInsuranceRequest,
  PatchedOrderAgentCreateRequest,
  PatchedOrderShipmentUnitCreateRequest,
  PatchedOrderUpdateRequest,
  PatchedPurchaseInvoiceUpdateRequest,
  PatchedSaleInvoiceBlankUpdateRequest,
  PatchedSaleInvoiceUpdateRequest,
  PatchedTaskCreateRequest,
  PurchaseInvoiceCreateRequest,
  PurchaseInvoiceDetailed,
  PurchaseInvoiceUpdateRequest,
  SaleInvoiceActOfWork,
  SaleInvoiceBlank,
  SaleInvoiceBlankResetFieldRequest,
  SaleInvoiceCloseResponse,
  SaleInvoiceCreateRequest,
  SaleInvoiceDetailed,
  SaleInvoiceOpenResponse,
  SaleInvoiceSetBlankRequest,
  SaleInvoiceSetClosedRequest,
  SaleInvoiceShipmentUnitCreate,
  SaleInvoiceShipmentUnitCreateRequest,
  SaleInvoiceUpdateRequest,
  TaskCreateRequest,
  UserSimple,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Orders<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Return non-archived orders. To list archived ones, explicitly use the `archived` query parameter
   *
   * @tags orders
   * @name OrdersList
   * @request GET:/api/v1/orders/
   * @secure
   * @response `200` `PaginatedOrderListList`
   */
  ordersList = (query: OrdersListParams, params: RequestParams = {}) =>
    this.http.request<PaginatedOrderListList, any>({
      path: `/api/v1/orders/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
 * @description Return detail-serialized created instance
 *
 * @tags orders
 * @name OrdersCreate
 * @request POST:/api/v1/orders/
 * @secure
 * @response `201` `OrderDetailed`
 * @response `400` `{
    type: OrdersCreateTypeEnum,
    error: {
    code: OrdersCreateCodeEnum,
    message: string,

},

}`
 */
  ordersCreate = (data: OrderCreateRequest, params: RequestParams = {}) =>
    this.http.request<
      OrderDetailed,
      {
        type: OrdersCreateTypeEnum;
        error: {
          code: OrdersCreateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Create, list, update and delete agents for the order The agents could be changed only for the active orders (not archived, etc).
   *
   * @tags orders
   * @name OrdersAgentsList
   * @request GET:/api/v1/orders/{orderPk}/agents/
   * @secure
   * @response `200` `(OrderAgent)[]`
   */
  ordersAgentsList = (orderPk: string, params: RequestParams = {}) =>
    this.http.request<OrderAgent[], any>({
      path: `/api/v1/orders/${orderPk}/agents/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
 * @description Create, list, update and delete agents for the order The agents could be changed only for the active orders (not archived, etc).
 *
 * @tags orders
 * @name OrdersAgentsCreate
 * @request POST:/api/v1/orders/{orderPk}/agents/
 * @secure
 * @response `201` `OrderAgent`
 * @response `400` `{
    type: OrdersAgentsCreateTypeEnum,
    error: {
    code: OrdersAgentsCreateCodeEnum,
    message: string,

},

}`
 */
  ordersAgentsCreate = (
    orderPk: string,
    data: OrderAgentCreateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      OrderAgent,
      {
        type: OrdersAgentsCreateTypeEnum;
        error: {
          code: OrdersAgentsCreateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${orderPk}/agents/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Create, list, update and delete agents for the order The agents could be changed only for the active orders (not archived, etc).
   *
   * @tags orders
   * @name OrdersAgentsRetrieve
   * @request GET:/api/v1/orders/{orderPk}/agents/{id}/
   * @secure
   * @response `200` `OrderAgent`
   */
  ordersAgentsRetrieve = (
    id: string,
    orderPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<OrderAgent, any>({
      path: `/api/v1/orders/${orderPk}/agents/${id}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
 * @description Create, list, update and delete agents for the order The agents could be changed only for the active orders (not archived, etc).
 *
 * @tags orders
 * @name OrdersAgentsUpdate
 * @request PUT:/api/v1/orders/{orderPk}/agents/{id}/
 * @secure
 * @response `200` `OrderAgent`
 * @response `400` `{
    type: OrdersAgentsUpdateTypeEnum,
    error: {
    code: OrdersAgentsUpdateCodeEnum,
    message: string,

},

}`
 */
  ordersAgentsUpdate = (
    id: string,
    orderPk: string,
    data: OrderAgentCreateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      OrderAgent,
      {
        type: OrdersAgentsUpdateTypeEnum;
        error: {
          code: OrdersAgentsUpdateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${orderPk}/agents/${id}/`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
 * @description Create, list, update and delete agents for the order The agents could be changed only for the active orders (not archived, etc).
 *
 * @tags orders
 * @name OrdersAgentsPartialUpdate
 * @request PATCH:/api/v1/orders/{orderPk}/agents/{id}/
 * @secure
 * @response `200` `OrderAgent`
 * @response `400` `{
    type: OrdersAgentsPartialUpdateTypeEnum,
    error: {
    code: OrdersAgentsPartialUpdateCodeEnum,
    message: string,

},

}`
 */
  ordersAgentsPartialUpdate = (
    id: string,
    orderPk: string,
    data: PatchedOrderAgentCreateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      OrderAgent,
      {
        type: OrdersAgentsPartialUpdateTypeEnum;
        error: {
          code: OrdersAgentsPartialUpdateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${orderPk}/agents/${id}/`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Create, list, update and delete agents for the order The agents could be changed only for the active orders (not archived, etc).
   *
   * @tags orders
   * @name OrdersAgentsDestroy
   * @request DELETE:/api/v1/orders/{orderPk}/agents/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  ordersAgentsDestroy = (
    id: string,
    orderPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/api/v1/orders/${orderPk}/agents/${id}/`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description Create, list, and delete documents for the order. The documents could be changed only for the active orders (not archived, etc).
   *
   * @tags orders
   * @name OrdersDocumentsList
   * @request GET:/api/v1/orders/{orderPk}/documents/
   * @secure
   * @response `200` `(OrderDocument)[]`
   */
  ordersDocumentsList = (orderPk: string, params: RequestParams = {}) =>
    this.http.request<OrderDocument[], any>({
      path: `/api/v1/orders/${orderPk}/documents/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Create, list, and delete documents for the order. The documents could be changed only for the active orders (not archived, etc).
   *
   * @tags orders
   * @name OrdersDocumentsCreate
   * @request POST:/api/v1/orders/{orderPk}/documents/
   * @secure
   * @response `201` `OrderDocument`
   */
  ordersDocumentsCreate = (
    orderPk: string,
    data: OrderDocumentRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<OrderDocument, any>({
      path: `/api/v1/orders/${orderPk}/documents/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Set (replace) tags for the order document. The order has to be **active** (not archived, etc).
   *
   * @tags orders
   * @name OrdersDocumentsTagsUpdate
   * @request PUT:/api/v1/orders/{orderPk}/documents/{orderDocumentPk}/tags/
   * @secure
   * @response `200` `(OrderDocumentTag)[]`
   */
  ordersDocumentsTagsUpdate = (
    orderDocumentPk: string,
    orderPk: string,
    data: AttachedDocumentTagSetRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<OrderDocumentTag[], any>({
      path: `/api/v1/orders/${orderPk}/documents/${orderDocumentPk}/tags/`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Create, list, and delete documents for the order. The documents could be changed only for the active orders (not archived, etc).
   *
   * @tags orders
   * @name OrdersDocumentsDestroy
   * @request DELETE:/api/v1/orders/{orderPk}/documents/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  ordersDocumentsDestroy = (
    id: string,
    orderPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/api/v1/orders/${orderPk}/documents/${id}/`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersInsurancesList
   * @request GET:/api/v1/orders/{orderPk}/insurances/
   * @secure
   * @response `200` `(Insurance)[]`
   */
  ordersInsurancesList = (orderPk: string, params: RequestParams = {}) =>
    this.http.request<Insurance[], any>({
      path: `/api/v1/orders/${orderPk}/insurances/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersInsurancesCreate
   * @request POST:/api/v1/orders/{orderPk}/insurances/
   * @secure
   * @response `201` `Insurance`
   */
  ordersInsurancesCreate = (
    orderPk: string,
    data: InsuranceCreateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<Insurance, any>({
      path: `/api/v1/orders/${orderPk}/insurances/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
 * @description Create, retrieve, and delete documents for the order. The documents could be changed only for the active orders (not archived, etc).
 *
 * @tags orders
 * @name OrdersInsurancesDocumentCreate
 * @request POST:/api/v1/orders/{orderPk}/insurances/{insurancePk}/document/
 * @secure
 * @response `201` `InsuranceDocument`
 * @response `400` `{
    type: OrdersInsurancesDocumentCreateTypeEnum,
    error: {
    code: OrdersInsurancesDocumentCreateCodeEnum,
    message: string,

},

}`
 */
  ordersInsurancesDocumentCreate = (
    insurancePk: string,
    orderPk: string,
    data: InsuranceDocumentRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      InsuranceDocument,
      {
        type: OrdersInsurancesDocumentCreateTypeEnum;
        error: {
          code: OrdersInsurancesDocumentCreateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${orderPk}/insurances/${insurancePk}/document/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
 * @description Detaches document from insurance. Deletion should occur via a periodic task. Advantages: 1. Safer: document can be restored if task hasn't run. 2. Faster: detachment is quicker without immediate deletion.
 *
 * @tags orders
 * @name OrdersInsurancesDocumentDestroy
 * @request DELETE:/api/v1/orders/{orderPk}/insurances/{insurancePk}/document/
 * @secure
 * @response `400` `{
    type: OrdersInsurancesDocumentDestroyTypeEnum,
    error: {
    code: OrdersInsurancesDocumentDestroyCodeEnum,
    message: string,

},

}`
 */
  ordersInsurancesDocumentDestroy = (
    insurancePk: string,
    orderPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<
      any,
      {
        type: OrdersInsurancesDocumentDestroyTypeEnum;
        error: {
          code: OrdersInsurancesDocumentDestroyCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${orderPk}/insurances/${insurancePk}/document/`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersInsurancesRetrieve
   * @request GET:/api/v1/orders/{orderPk}/insurances/{id}/
   * @secure
   * @response `200` `Insurance`
   */
  ordersInsurancesRetrieve = (
    id: string,
    orderPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<Insurance, any>({
      path: `/api/v1/orders/${orderPk}/insurances/${id}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
 * @description Return detail-serialized created instance
 *
 * @tags orders
 * @name OrdersInsurancesUpdate
 * @request PUT:/api/v1/orders/{orderPk}/insurances/{id}/
 * @secure
 * @response `200` `Insurance`
 * @response `400` `{
    type: OrdersInsurancesUpdateTypeEnum,
    error: {
    code: OrdersInsurancesUpdateCodeEnum,
    message: string,

},

}`
 */
  ordersInsurancesUpdate = (
    id: string,
    orderPk: string,
    data: InsuranceRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      Insurance,
      {
        type: OrdersInsurancesUpdateTypeEnum;
        error: {
          code: OrdersInsurancesUpdateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${orderPk}/insurances/${id}/`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
 * @description Return detail-serialized created instance
 *
 * @tags orders
 * @name OrdersInsurancesPartialUpdate
 * @request PATCH:/api/v1/orders/{orderPk}/insurances/{id}/
 * @secure
 * @response `200` `Insurance`
 * @response `400` `{
    type: OrdersInsurancesPartialUpdateTypeEnum,
    error: {
    code: OrdersInsurancesPartialUpdateCodeEnum,
    message: string,

},

}`
 */
  ordersInsurancesPartialUpdate = (
    id: string,
    orderPk: string,
    data: PatchedInsuranceRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      Insurance,
      {
        type: OrdersInsurancesPartialUpdateTypeEnum;
        error: {
          code: OrdersInsurancesPartialUpdateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${orderPk}/insurances/${id}/`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersInsurancesDestroy
   * @request DELETE:/api/v1/orders/{orderPk}/insurances/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  ordersInsurancesDestroy = (
    id: string,
    orderPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/api/v1/orders/${orderPk}/insurances/${id}/`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersInvoicesForPurchaseList
   * @request GET:/api/v1/orders/{orderPk}/invoices-for-purchase/
   * @secure
   * @response `200` `(InvoiceListForOrder)[]`
   */
  ordersInvoicesForPurchaseList = (
    orderPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<InvoiceListForOrder[], any>({
      path: `/api/v1/orders/${orderPk}/invoices-for-purchase/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
 * @description Return detail-serialized created instance
 *
 * @tags orders
 * @name OrdersInvoicesForPurchaseCreate
 * @request POST:/api/v1/orders/{orderPk}/invoices-for-purchase/
 * @secure
 * @response `201` `PurchaseInvoiceDetailed`
 * @response `400` `{
    type: OrdersInvoicesForPurchaseCreateTypeEnum,
    error: {
    code: OrdersInvoicesForPurchaseCreateCodeEnum,
    message: string,

},

}`
 */
  ordersInvoicesForPurchaseCreate = (
    orderPk: string,
    data: PurchaseInvoiceCreateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      PurchaseInvoiceDetailed,
      {
        type: OrdersInvoicesForPurchaseCreateTypeEnum;
        error: {
          code: OrdersInvoicesForPurchaseCreateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${orderPk}/invoices-for-purchase/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
 * No description
 *
 * @tags orders
 * @name OrdersInvoicesForPurchaseDocumentCreate
 * @request POST:/api/v1/orders/{orderPk}/invoices-for-purchase/{id}/document/
 * @secure
 * @response `400` `{
    type: OrdersInvoicesForPurchaseDocumentCreateTypeEnum,
    error: {
    code: OrdersInvoicesForPurchaseDocumentCreateCodeEnum,
    message: string,

},

}`
 */
  ordersInvoicesForPurchaseDocumentCreate = (
    id: string,
    orderPk: string,
    data: InvoiceDocumentCreateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      any,
      {
        type: OrdersInvoicesForPurchaseDocumentCreateTypeEnum;
        error: {
          code: OrdersInvoicesForPurchaseDocumentCreateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${orderPk}/invoices-for-purchase/${id}/document/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
 * No description
 *
 * @tags orders
 * @name OrdersInvoicesForPurchaseDocumentDestroy
 * @request DELETE:/api/v1/orders/{orderPk}/invoices-for-purchase/{id}/document/
 * @secure
 * @response `400` `{
    type: OrdersInvoicesForPurchaseDocumentDestroyTypeEnum,
    error: {
    code: OrdersInvoicesForPurchaseDocumentDestroyCodeEnum,
    message: string,

},

}`
 */
  ordersInvoicesForPurchaseDocumentDestroy = (
    id: string,
    orderPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<
      any,
      {
        type: OrdersInvoicesForPurchaseDocumentDestroyTypeEnum;
        error: {
          code: OrdersInvoicesForPurchaseDocumentDestroyCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${orderPk}/invoices-for-purchase/${id}/document/`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersInvoicesForPurchaseRetrieve
   * @request GET:/api/v1/orders/{orderPk}/invoices-for-purchase/{id}/
   * @secure
   * @response `200` `PurchaseInvoiceDetailed`
   */
  ordersInvoicesForPurchaseRetrieve = (
    id: string,
    orderPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<PurchaseInvoiceDetailed, any>({
      path: `/api/v1/orders/${orderPk}/invoices-for-purchase/${id}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
 * @description Return detail-serialized created instance
 *
 * @tags orders
 * @name OrdersInvoicesForPurchaseUpdate
 * @request PUT:/api/v1/orders/{orderPk}/invoices-for-purchase/{id}/
 * @secure
 * @response `200` `PurchaseInvoiceDetailed`
 * @response `400` `{
    type: OrdersInvoicesForPurchaseUpdateTypeEnum,
    error: {
    code: OrdersInvoicesForPurchaseUpdateCodeEnum,
    message: string,

},

}`
 */
  ordersInvoicesForPurchaseUpdate = (
    id: string,
    orderPk: string,
    data: PurchaseInvoiceUpdateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      PurchaseInvoiceDetailed,
      {
        type: OrdersInvoicesForPurchaseUpdateTypeEnum;
        error: {
          code: OrdersInvoicesForPurchaseUpdateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${orderPk}/invoices-for-purchase/${id}/`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
 * @description Return detail-serialized created instance
 *
 * @tags orders
 * @name OrdersInvoicesForPurchasePartialUpdate
 * @request PATCH:/api/v1/orders/{orderPk}/invoices-for-purchase/{id}/
 * @secure
 * @response `200` `PurchaseInvoiceDetailed`
 * @response `400` `{
    type: OrdersInvoicesForPurchasePartialUpdateTypeEnum,
    error: {
    code: OrdersInvoicesForPurchasePartialUpdateCodeEnum,
    message: string,

},

}`
 */
  ordersInvoicesForPurchasePartialUpdate = (
    id: string,
    orderPk: string,
    data: PatchedPurchaseInvoiceUpdateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      PurchaseInvoiceDetailed,
      {
        type: OrdersInvoicesForPurchasePartialUpdateTypeEnum;
        error: {
          code: OrdersInvoicesForPurchasePartialUpdateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${orderPk}/invoices-for-purchase/${id}/`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersInvoicesForPurchaseDestroy
   * @request DELETE:/api/v1/orders/{orderPk}/invoices-for-purchase/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  ordersInvoicesForPurchaseDestroy = (
    id: string,
    orderPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/api/v1/orders/${orderPk}/invoices-for-purchase/${id}/`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersInvoicesForSaleList
   * @request GET:/api/v1/orders/{orderPk}/invoices-for-sale/
   * @secure
   * @response `200` `(InvoiceListForOrder)[]`
   */
  ordersInvoicesForSaleList = (orderPk: string, params: RequestParams = {}) =>
    this.http.request<InvoiceListForOrder[], any>({
      path: `/api/v1/orders/${orderPk}/invoices-for-sale/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
 * @description Return detail-serialized created instance
 *
 * @tags orders
 * @name OrdersInvoicesForSaleCreate
 * @request POST:/api/v1/orders/{orderPk}/invoices-for-sale/
 * @secure
 * @response `201` `SaleInvoiceDetailed`
 * @response `400` `{
    type: OrdersInvoicesForSaleCreateTypeEnum,
    error: {
    code: OrdersInvoicesForSaleCreateCodeEnum,
    message: string,

},

}`
 */
  ordersInvoicesForSaleCreate = (
    orderPk: string,
    data: SaleInvoiceCreateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      SaleInvoiceDetailed,
      {
        type: OrdersInvoicesForSaleCreateTypeEnum;
        error: {
          code: OrdersInvoicesForSaleCreateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${orderPk}/invoices-for-sale/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags orders
   * @name OrdersInvoicesForSaleActOfWorkRetrieve
   * @request GET:/api/v1/orders/{orderPk}/invoices-for-sale/{invoicePk}/act-of-work/
   * @secure
   * @response `200` `SaleInvoiceActOfWork`
   */
  ordersInvoicesForSaleActOfWorkRetrieve = (
    invoicePk: string,
    orderPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<SaleInvoiceActOfWork, any>({
      path: `/api/v1/orders/${orderPk}/invoices-for-sale/${invoicePk}/act-of-work/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Common method for SaleInvoiceBlank views.
   *
   * @tags orders
   * @name OrdersInvoicesForSaleBlankRetrieve
   * @request GET:/api/v1/orders/{orderPk}/invoices-for-sale/{invoicePk}/blank/
   * @secure
   * @response `200` `SaleInvoiceBlank`
   */
  ordersInvoicesForSaleBlankRetrieve = (
    invoicePk: string,
    orderPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<SaleInvoiceBlank, any>({
      path: `/api/v1/orders/${orderPk}/invoices-for-sale/${invoicePk}/blank/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
 * @description Common method for SaleInvoiceBlank views.
 *
 * @tags orders
 * @name OrdersInvoicesForSaleBlankPartialUpdate
 * @request PATCH:/api/v1/orders/{orderPk}/invoices-for-sale/{invoicePk}/blank/
 * @secure
 * @response `200` `SaleInvoiceBlank`
 * @response `400` `{
    type: OrdersInvoicesForSaleBlankPartialUpdateTypeEnum,
    error: {
    code: OrdersInvoicesForSaleBlankPartialUpdateCodeEnum,
    message: string,

},

}`
 */
  ordersInvoicesForSaleBlankPartialUpdate = (
    invoicePk: string,
    orderPk: string,
    data: PatchedSaleInvoiceBlankUpdateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      SaleInvoiceBlank,
      {
        type: OrdersInvoicesForSaleBlankPartialUpdateTypeEnum;
        error: {
          code: OrdersInvoicesForSaleBlankPartialUpdateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${orderPk}/invoices-for-sale/${invoicePk}/blank/`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
 * @description Reset sale invoice blank field to default.
 *
 * @tags orders
 * @name OrdersInvoicesForSaleBlankResetCreate
 * @request POST:/api/v1/orders/{orderPk}/invoices-for-sale/{invoicePk}/blank/reset/
 * @secure
 * @response `200` `SaleInvoiceBlank`
 * @response `400` `{
    type: OrdersInvoicesForSaleBlankResetCreateTypeEnum,
    error: {
    code: OrdersInvoicesForSaleBlankResetCreateCodeEnum,
    message: string,

},

}`
 */
  ordersInvoicesForSaleBlankResetCreate = (
    invoicePk: string,
    orderPk: string,
    data: SaleInvoiceBlankResetFieldRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      SaleInvoiceBlank,
      {
        type: OrdersInvoicesForSaleBlankResetCreateTypeEnum;
        error: {
          code: OrdersInvoicesForSaleBlankResetCreateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${orderPk}/invoices-for-sale/${invoicePk}/blank/reset/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
 * No description
 *
 * @tags orders
 * @name OrdersInvoicesForSaleShipmentUnitsCreate
 * @request POST:/api/v1/orders/{orderPk}/invoices-for-sale/{invoicePk}/shipment-units/
 * @secure
 * @response `201` `SaleInvoiceShipmentUnitCreate`
 * @response `400` `{
    type: OrdersInvoicesForSaleShipmentUnitsCreateTypeEnum,
    error: {
    code: OrdersInvoicesForSaleShipmentUnitsCreateCodeEnum,
    message: string,

},

}`
 */
  ordersInvoicesForSaleShipmentUnitsCreate = (
    invoicePk: string,
    orderPk: string,
    data: SaleInvoiceShipmentUnitCreateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      SaleInvoiceShipmentUnitCreate,
      {
        type: OrdersInvoicesForSaleShipmentUnitsCreateTypeEnum;
        error: {
          code: OrdersInvoicesForSaleShipmentUnitsCreateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${orderPk}/invoices-for-sale/${invoicePk}/shipment-units/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
 * No description
 *
 * @tags orders
 * @name OrdersInvoicesForSaleShipmentUnitsDestroy
 * @request DELETE:/api/v1/orders/{orderPk}/invoices-for-sale/{invoicePk}/shipment-units/{shipmentUnitPk}/
 * @secure
 * @response `204` `void` No response body
 * @response `400` `{
    type: OrdersInvoicesForSaleShipmentUnitsDestroyTypeEnum,
    error: {
    code: OrdersInvoicesForSaleShipmentUnitsDestroyCodeEnum,
    message: string,

},

}`
 */
  ordersInvoicesForSaleShipmentUnitsDestroy = (
    invoicePk: string,
    orderPk: string,
    shipmentUnitPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<
      void,
      {
        type: OrdersInvoicesForSaleShipmentUnitsDestroyTypeEnum;
        error: {
          code: OrdersInvoicesForSaleShipmentUnitsDestroyCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${orderPk}/invoices-for-sale/${invoicePk}/shipment-units/${shipmentUnitPk}/`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersInvoicesForSaleRetrieve
   * @request GET:/api/v1/orders/{orderPk}/invoices-for-sale/{id}/
   * @secure
   * @response `200` `SaleInvoiceDetailed`
   */
  ordersInvoicesForSaleRetrieve = (
    id: string,
    orderPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<SaleInvoiceDetailed, any>({
      path: `/api/v1/orders/${orderPk}/invoices-for-sale/${id}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
 * @description Return detail-serialized created instance
 *
 * @tags orders
 * @name OrdersInvoicesForSaleUpdate
 * @request PUT:/api/v1/orders/{orderPk}/invoices-for-sale/{id}/
 * @secure
 * @response `200` `SaleInvoiceDetailed`
 * @response `400` `{
    type: OrdersInvoicesForSaleUpdateTypeEnum,
    error: {
    code: OrdersInvoicesForSaleUpdateCodeEnum,
    message: string,

},

}`
 */
  ordersInvoicesForSaleUpdate = (
    id: string,
    orderPk: string,
    data: SaleInvoiceUpdateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      SaleInvoiceDetailed,
      {
        type: OrdersInvoicesForSaleUpdateTypeEnum;
        error: {
          code: OrdersInvoicesForSaleUpdateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${orderPk}/invoices-for-sale/${id}/`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
 * @description Return detail-serialized created instance
 *
 * @tags orders
 * @name OrdersInvoicesForSalePartialUpdate
 * @request PATCH:/api/v1/orders/{orderPk}/invoices-for-sale/{id}/
 * @secure
 * @response `200` `SaleInvoiceDetailed`
 * @response `400` `{
    type: OrdersInvoicesForSalePartialUpdateTypeEnum,
    error: {
    code: OrdersInvoicesForSalePartialUpdateCodeEnum,
    message: string,

},

}`
 */
  ordersInvoicesForSalePartialUpdate = (
    id: string,
    orderPk: string,
    data: PatchedSaleInvoiceUpdateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      SaleInvoiceDetailed,
      {
        type: OrdersInvoicesForSalePartialUpdateTypeEnum;
        error: {
          code: OrdersInvoicesForSalePartialUpdateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${orderPk}/invoices-for-sale/${id}/`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersInvoicesForSaleDestroy
   * @request DELETE:/api/v1/orders/{orderPk}/invoices-for-sale/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  ordersInvoicesForSaleDestroy = (
    id: string,
    orderPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/api/v1/orders/${orderPk}/invoices-for-sale/${id}/`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
 * @description Return detail-serialized created instance
 *
 * @tags orders
 * @name OrdersInvoicesForSaleCloseCreate
 * @request POST:/api/v1/orders/{orderPk}/invoices-for-sale/{id}/close/
 * @secure
 * @response `200` `SaleInvoiceCloseResponse`
 * @response `400` `{
    type: OrdersInvoicesForSaleCloseCreateTypeEnum,
    error: {
    code: OrdersInvoicesForSaleCloseCreateCodeEnum,
    message: string,

},

}`
 */
  ordersInvoicesForSaleCloseCreate = (
    id: string,
    orderPk: string,
    data: SaleInvoiceSetClosedRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      SaleInvoiceCloseResponse,
      {
        type: OrdersInvoicesForSaleCloseCreateTypeEnum;
        error: {
          code: OrdersInvoicesForSaleCloseCreateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${orderPk}/invoices-for-sale/${id}/close/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
 * @description Return detail-serialized created instance
 *
 * @tags orders
 * @name OrdersInvoicesForSaleGeneratePdfCreate
 * @request POST:/api/v1/orders/{orderPk}/invoices-for-sale/{id}/generate-pdf/
 * @secure
 * @response `200` `InvoiceDocumentCreate`
 * @response `400` `{
    type: OrdersInvoicesForSaleGeneratePdfCreateTypeEnum,
    error: {
    code: OrdersInvoicesForSaleGeneratePdfCreateCodeEnum,
    message: string,

},

}`
 */
  ordersInvoicesForSaleGeneratePdfCreate = (
    id: string,
    orderPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<
      InvoiceDocumentCreate,
      {
        type: OrdersInvoicesForSaleGeneratePdfCreateTypeEnum;
        error: {
          code: OrdersInvoicesForSaleGeneratePdfCreateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${orderPk}/invoices-for-sale/${id}/generate-pdf/`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersInvoicesForSaleOpenCreate
   * @request POST:/api/v1/orders/{orderPk}/invoices-for-sale/{id}/open/
   * @secure
   * @response `200` `SaleInvoiceOpenResponse`
   */
  ordersInvoicesForSaleOpenCreate = (
    id: string,
    orderPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<SaleInvoiceOpenResponse, any>({
      path: `/api/v1/orders/${orderPk}/invoices-for-sale/${id}/open/`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
 * @description replace invoice information based on order data. If you want update information manually, use PATCH /invoices-for-sale/pk/ data={'information': {...}} endpoint
 *
 * @tags orders
 * @name OrdersInvoicesForSaleSetOrderInformationCreate
 * @request POST:/api/v1/orders/{orderPk}/invoices-for-sale/{id}/set-order-information/
 * @secure
 * @response `200` `SaleInvoiceBlank`
 * @response `400` `{
    type: OrdersInvoicesForSaleSetOrderInformationCreateTypeEnum,
    error: {
    code: OrdersInvoicesForSaleSetOrderInformationCreateCodeEnum,
    message: string,

},

}`
 */
  ordersInvoicesForSaleSetOrderInformationCreate = (
    id: string,
    orderPk: string,
    data: SaleInvoiceSetBlankRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      SaleInvoiceBlank,
      {
        type: OrdersInvoicesForSaleSetOrderInformationCreateTypeEnum;
        error: {
          code: OrdersInvoicesForSaleSetOrderInformationCreateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${orderPk}/invoices-for-sale/${id}/set-order-information/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
 * @description Set (replace) order labels for the **active** (not archived, etc.) order. The complete list of the labels should be provided. The existing labels will be replaced with the new ones.
 *
 * @tags orders
 * @name OrdersLabelsUpdate
 * @request PUT:/api/v1/orders/{orderPk}/labels/
 * @secure
 * @response `200` `(Label)[]`
 * @response `400` `{
    type: OrdersLabelsUpdateTypeEnum,
    error: {
    code: OrdersLabelsUpdateCodeEnum,
    message: string,

},

}`
 */
  ordersLabelsUpdate = (
    orderPk: string,
    data: OrderLabelSetRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      Label[],
      {
        type: OrdersLabelsUpdateTypeEnum;
        error: {
          code: OrdersLabelsUpdateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${orderPk}/labels/`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
 * @description Set (replace) order managers for the **active** (not archived, etc.) order. The complete list of the managers should be provided. The existing managers will be replaced with the new ones.
 *
 * @tags orders
 * @name OrdersManagersUpdate
 * @request PUT:/api/v1/orders/{orderPk}/managers/
 * @secure
 * @response `200` `(UserSimple)[]`
 * @response `400` `{
    type: OrdersManagersUpdateTypeEnum,
    error: {
    code: OrdersManagersUpdateCodeEnum,
    message: string,

},

}`
 */
  ordersManagersUpdate = (
    orderPk: string,
    data: OrderManagerSetRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      UserSimple[],
      {
        type: OrdersManagersUpdateTypeEnum;
        error: {
          code: OrdersManagersUpdateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${orderPk}/managers/`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersShipmentUnitsList
   * @request GET:/api/v1/orders/{orderPk}/shipment-units/
   * @secure
   * @response `200` `(OrderShipmentUnit)[]`
   */
  ordersShipmentUnitsList = (orderPk: string, params: RequestParams = {}) =>
    this.http.request<OrderShipmentUnit[], any>({
      path: `/api/v1/orders/${orderPk}/shipment-units/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersShipmentUnitsCreate
   * @request POST:/api/v1/orders/{orderPk}/shipment-units/
   * @secure
   * @response `201` `OrderShipmentUnit`
   */
  ordersShipmentUnitsCreate = (
    orderPk: string,
    data: OrderShipmentUnitCreateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<OrderShipmentUnit, any>({
      path: `/api/v1/orders/${orderPk}/shipment-units/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersShipmentUnitsRetrieve
   * @request GET:/api/v1/orders/{orderPk}/shipment-units/{id}/
   * @secure
   * @response `200` `OrderShipmentUnit`
   */
  ordersShipmentUnitsRetrieve = (
    id: string,
    orderPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<OrderShipmentUnit, any>({
      path: `/api/v1/orders/${orderPk}/shipment-units/${id}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersShipmentUnitsUpdate
   * @request PUT:/api/v1/orders/{orderPk}/shipment-units/{id}/
   * @secure
   * @response `200` `OrderShipmentUnit`
   */
  ordersShipmentUnitsUpdate = (
    id: string,
    orderPk: string,
    data: OrderShipmentUnitCreateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<OrderShipmentUnit, any>({
      path: `/api/v1/orders/${orderPk}/shipment-units/${id}/`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersShipmentUnitsPartialUpdate
   * @request PATCH:/api/v1/orders/{orderPk}/shipment-units/{id}/
   * @secure
   * @response `200` `OrderShipmentUnit`
   */
  ordersShipmentUnitsPartialUpdate = (
    id: string,
    orderPk: string,
    data: PatchedOrderShipmentUnitCreateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<OrderShipmentUnit, any>({
      path: `/api/v1/orders/${orderPk}/shipment-units/${id}/`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
 * @description Return detail-serialized created instance
 *
 * @tags orders
 * @name OrdersShipmentUnitsDestroy
 * @request DELETE:/api/v1/orders/{orderPk}/shipment-units/{id}/
 * @secure
 * @response `204` `void` No response body
 * @response `400` `{
    type: OrdersShipmentUnitsDestroyTypeEnum,
    error: {
    code: OrdersShipmentUnitsDestroyCodeEnum,
    message: string,

},

}`
 */
  ordersShipmentUnitsDestroy = (
    id: string,
    orderPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<
      void,
      {
        type: OrdersShipmentUnitsDestroyTypeEnum;
        error: {
          code: OrdersShipmentUnitsDestroyCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${orderPk}/shipment-units/${id}/`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersShipmentUnitsShipmentReferencesCreate
   * @request POST:/api/v1/orders/{orderPk}/shipment-units/{shipmentUnitPk}/shipment-references/
   * @secure
   * @response `201` `OrderShipmentReference`
   */
  ordersShipmentUnitsShipmentReferencesCreate = (
    orderPk: string,
    shipmentUnitPk: string,
    data: OrderShipmentReferenceRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<OrderShipmentReference, any>({
      path: `/api/v1/orders/${orderPk}/shipment-units/${shipmentUnitPk}/shipment-references/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersShipmentUnitsShipmentReferencesRetrieve
   * @request GET:/api/v1/orders/{orderPk}/shipment-units/{shipmentUnitPk}/shipment-references/{id}/
   * @secure
   * @response `200` `OrderShipmentReference`
   */
  ordersShipmentUnitsShipmentReferencesRetrieve = (
    id: string,
    orderPk: string,
    shipmentUnitPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<OrderShipmentReference, any>({
      path: `/api/v1/orders/${orderPk}/shipment-units/${shipmentUnitPk}/shipment-references/${id}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersShipmentUnitsShipmentReferencesUpdate
   * @request PUT:/api/v1/orders/{orderPk}/shipment-units/{shipmentUnitPk}/shipment-references/{id}/
   * @secure
   * @response `200` `OrderShipmentReference`
   */
  ordersShipmentUnitsShipmentReferencesUpdate = (
    id: string,
    orderPk: string,
    shipmentUnitPk: string,
    data: OrderShipmentReferenceRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<OrderShipmentReference, any>({
      path: `/api/v1/orders/${orderPk}/shipment-units/${shipmentUnitPk}/shipment-references/${id}/`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersShipmentUnitsShipmentReferencesDestroy
   * @request DELETE:/api/v1/orders/{orderPk}/shipment-units/{shipmentUnitPk}/shipment-references/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  ordersShipmentUnitsShipmentReferencesDestroy = (
    id: string,
    orderPk: string,
    shipmentUnitPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/api/v1/orders/${orderPk}/shipment-units/${shipmentUnitPk}/shipment-references/${id}/`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersTasksList
   * @request GET:/api/v1/orders/{orderPk}/tasks/
   * @secure
   * @response `200` `(OrderTask)[]`
   */
  ordersTasksList = (orderPk: string, params: RequestParams = {}) =>
    this.http.request<OrderTask[], any>({
      path: `/api/v1/orders/${orderPk}/tasks/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersTasksCreate
   * @request POST:/api/v1/orders/{orderPk}/tasks/
   * @secure
   * @response `201` `OrderTask`
   */
  ordersTasksCreate = (
    orderPk: string,
    data: TaskCreateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<OrderTask, any>({
      path: `/api/v1/orders/${orderPk}/tasks/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersTasksRetrieve
   * @request GET:/api/v1/orders/{orderPk}/tasks/{id}/
   * @secure
   * @response `200` `OrderTask`
   */
  ordersTasksRetrieve = (
    id: string,
    orderPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<OrderTask, any>({
      path: `/api/v1/orders/${orderPk}/tasks/${id}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersTasksUpdate
   * @request PUT:/api/v1/orders/{orderPk}/tasks/{id}/
   * @secure
   * @response `200` `OrderTask`
   */
  ordersTasksUpdate = (
    id: string,
    orderPk: string,
    data: TaskCreateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<OrderTask, any>({
      path: `/api/v1/orders/${orderPk}/tasks/${id}/`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersTasksPartialUpdate
   * @request PATCH:/api/v1/orders/{orderPk}/tasks/{id}/
   * @secure
   * @response `200` `OrderTask`
   */
  ordersTasksPartialUpdate = (
    id: string,
    orderPk: string,
    data: PatchedTaskCreateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<OrderTask, any>({
      path: `/api/v1/orders/${orderPk}/tasks/${id}/`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersTasksDestroy
   * @request DELETE:/api/v1/orders/{orderPk}/tasks/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  ordersTasksDestroy = (
    id: string,
    orderPk: string,
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/api/v1/orders/${orderPk}/tasks/${id}/`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description Return detail-serialized created instance
   *
   * @tags orders
   * @name OrdersRetrieve
   * @request GET:/api/v1/orders/{id}/
   * @secure
   * @response `200` `OrderDetailed`
   */
  ordersRetrieve = (id: string, params: RequestParams = {}) =>
    this.http.request<OrderDetailed, any>({
      path: `/api/v1/orders/${id}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
 * @description Return detail-serialized created instance
 *
 * @tags orders
 * @name OrdersUpdate
 * @request PUT:/api/v1/orders/{id}/
 * @secure
 * @response `200` `OrderDetailed`
 * @response `400` `{
    type: OrdersUpdateTypeEnum,
    error: {
    code: OrdersUpdateCodeEnum,
    message: string,

},

}`
 */
  ordersUpdate = (
    id: string,
    data: OrderUpdateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      OrderDetailed,
      {
        type: OrdersUpdateTypeEnum;
        error: {
          code: OrdersUpdateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${id}/`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
 * @description Return detail-serialized created instance
 *
 * @tags orders
 * @name OrdersPartialUpdate
 * @request PATCH:/api/v1/orders/{id}/
 * @secure
 * @response `200` `OrderDetailed`
 * @response `400` `{
    type: OrdersPartialUpdateTypeEnum,
    error: {
    code: OrdersPartialUpdateCodeEnum,
    message: string,

},

}`
 */
  ordersPartialUpdate = (
    id: string,
    data: PatchedOrderUpdateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      OrderDetailed,
      {
        type: OrdersPartialUpdateTypeEnum;
        error: {
          code: OrdersPartialUpdateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${id}/`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
 * @description Return detail-serialized created instance
 *
 * @tags orders
 * @name OrdersDestroy
 * @request DELETE:/api/v1/orders/{id}/
 * @secure
 * @response `204` `void` No response body
 * @response `400` `{
    type: OrdersDestroyTypeEnum,
    error: {
    code: OrdersDestroyCodeEnum,
    message: string,

},

}`
 */
  ordersDestroy = (id: string, params: RequestParams = {}) =>
    this.http.request<
      void,
      {
        type: OrdersDestroyTypeEnum;
        error: {
          code: OrdersDestroyCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${id}/`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
 * @description Return detail-serialized created instance
 *
 * @tags orders
 * @name OrdersSetArchivedCreate
 * @request POST:/api/v1/orders/{id}/set-archived/
 * @secure
 * @response `200` `OrderArchiveResponse`
 * @response `400` `{
    type: OrdersSetArchivedCreateTypeEnum,
    error: {
    code: OrdersSetArchivedCreateCodeEnum,
    message: string,

},

}`
 */
  ordersSetArchivedCreate = (
    id: string,
    data: OrderArchiveRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<
      OrderArchiveResponse,
      {
        type: OrdersSetArchivedCreateTypeEnum;
        error: {
          code: OrdersSetArchivedCreateCodeEnum;
          message: string;
        };
      }
    >({
      path: `/api/v1/orders/${id}/set-archived/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
