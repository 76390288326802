/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Task, TasksListParams } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Tasks<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags tasks
   * @name TasksList
   * @request GET:/api/v1/tasks/
   * @secure
   * @response `200` `(Task)[]`
   */
  tasksList = (query: TasksListParams, params: RequestParams = {}) =>
    this.http.request<Task[], any>({
      path: `/api/v1/tasks/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
