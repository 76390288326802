/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  JSONWebToken,
  JSONWebTokenRequest,
  RefreshAuthToken,
  RefreshAuthTokenRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Auth<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description API View that receives a POST with a user's username and password. Returns a JSON Web Token that can be used for authenticated requests.
   *
   * @tags auth
   * @name AuthTokenCreate
   * @request POST:/api/v1/auth/token/
   * @response `200` `JSONWebToken`
   */
  authTokenCreate = (data: JSONWebTokenRequest, params: RequestParams = {}) =>
    this.http.request<JSONWebToken, any>({
      path: `/api/v1/auth/token/`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description API View that returns a refreshed token (with new expiration) based on existing token If 'orig_iat' field (original issued-at-time) is found it will first check if it's within expiration window, then copy it to the new token.
   *
   * @tags auth
   * @name AuthTokenRefreshCreate
   * @request POST:/api/v1/auth/token/refresh/
   * @response `200` `RefreshAuthToken`
   */
  authTokenRefreshCreate = (
    data: RefreshAuthTokenRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<RefreshAuthToken, any>({
      path: `/api/v1/auth/token/refresh/`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
