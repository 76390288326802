export default defineNuxtRouteMiddleware((to) => {
  const { isAuthorized } = useAuth();

  const PUBLIC_ROUTES = ['login'];

  if (!isAuthorized) {
    if (!PUBLIC_ROUTES.includes(to.name as string)) {
      return navigateTo({ name: 'login' });
    }
  }
});
