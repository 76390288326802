/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { OrderDocumentTag } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class OrderDocumentTags<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags order-document-tags
   * @name OrderDocumentTagsList
   * @request GET:/api/v1/order-document-tags/
   * @secure
   * @response `200` `(OrderDocumentTag)[]`
   */
  orderDocumentTagsList = (params: RequestParams = {}) =>
    this.http.request<OrderDocumentTag[], any>({
      path: `/api/v1/order-document-tags/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
