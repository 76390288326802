import { useRouteQuery } from '@vueuse/router';
import { OrdersListParams } from '~/api/data-contracts';
import dayjs from '~/utils/dayjs';
import { BACKEND_DATE_FORMAT, toBackendDate } from '~/utils/toBackendDate';

export const PAGE_SIZE = 100;

export const useOrdersFilters = () => {
  const managedByUser = useRouteQuery('managedByUser', undefined, {
    transform: (value) => (value === 'true' ? true : undefined),
  });
  const page = useRouteQuery('page', '1', {
    transform: Number,
  });
  const search = useRouteQuery('search', '');
  const archived = useRouteQuery('archived', undefined, {
    transform: (value) => (value === 'true' ? true : undefined),
  });

  const transportationMethods = useRouteQuery<string[]>(
    'transportationMethods',
    [],
  );

  const clientIds = useRouteQuery<string[]>('clientIds', [], {
    transform(val) {
      return Array.isArray(val) ? val : [val];
    },
  });
  const agentIds = useRouteQuery<string[]>('agentIds', [], {
    transform(val) {
      return Array.isArray(val) ? val : [val];
    },
  });
  const labelIds = useRouteQuery<string[]>('labelIds', [], {
    transform(val) {
      return Array.isArray(val) ? val : [val];
    },
  });
  const managedByUserIds = useRouteQuery<string[]>('managedByUserIds', [], {
    transform(val) {
      return Array.isArray(val) ? val : [val];
    },
  });

  const creationDateAfter = useRouteQuery<string | null>(
    'creationDateAfter',
    null,
  );
  const creationDateBefore = useRouteQuery<string | null>(
    'creationDateBefore',
    null,
  );

  const calendar = computed<[Date, Date | null] | undefined>({
    get() {
      const value: Date[] = [];

      if (creationDateAfter.value) {
        value[0] = dayjs(creationDateAfter.value, BACKEND_DATE_FORMAT).toDate();
      }
      if (creationDateBefore.value) {
        value[1] = dayjs(
          creationDateBefore.value,
          BACKEND_DATE_FORMAT,
        ).toDate();
      }

      if (value.length === 0) return undefined;
      return value as [Date, Date | null];
    },
    set(newValue) {
      if (newValue && newValue[0]) {
        creationDateAfter.value = toBackendDate(newValue[0]);
      } else {
        creationDateAfter.value = null;
      }
      if (newValue && newValue[1]) {
        creationDateBefore.value = toBackendDate(newValue[1]);
      } else {
        creationDateBefore.value = null;
      }
    },
  });

  const filters = computed<OrdersListParams>(() => {
    return {
      managedByUser: managedByUser.value,
      page: page.value,
      search: search.value,
      archived: archived.value,
      //
      transportationMethods: transportationMethods.value,
      clientIds: clientIds.value,
      agentIds: agentIds.value,
      labelIds: labelIds.value,
      managedByUserIds: managedByUserIds.value,
      creationDateAfter: creationDateAfter.value
        ? creationDateAfter.value
        : undefined,
      creationDateBefore: creationDateBefore.value
        ? creationDateBefore.value
        : undefined,
      page_size: PAGE_SIZE,
    };
  });

  return {
    calendar,
    creationDateAfter,
    creationDateBefore,
    managedByUser,
    page,
    search,
    transportationMethods,
    clientIds,
    agentIds,
    labelIds,
    managedByUserIds,
    archived,
    filters,
  };
};
