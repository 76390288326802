import { HttpClient } from '~/api/http-client';

const http = new HttpClient();
http.instance.interceptors.request.use((config) => {
  const { token } = useAuth();

  if (token.value) {
    config.headers.Authorization = `Bearer ${token.value}`;
  }

  // #FIXME Idk why, but typescript sdk generator generates array params as array instead of string with commas
  if (config.params !== undefined) {
    Object.keys(config.params).forEach((paramName) => {
      if (
        Array.isArray(config.params[paramName]) &&
        config.params[paramName].length > 0
      ) {
        config.params[paramName] = config.params[paramName].join(',');
      }
    });
  }

  return config;
});

http.instance.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    if (error.response.status === 401) {
      useAuth().logout();
    }
    return Promise.reject(error);
  },
);

export default http;
