import {
  QueryClient,
  queryOptions,
  useMutation,
  useQuery,
} from '@tanstack/vue-query';
import { PatchedUserUpdateRequest } from '~/api/data-contracts';
import { usersApi } from '~/utils/api';

export const userQueryKey = () => ['user'];
export const invalidateUser = (queryClient: QueryClient) =>
  queryClient.invalidateQueries({ queryKey: userQueryKey() });

const userQueryConfig = () =>
  queryOptions({
    queryKey: userQueryKey(),
    queryFn: () => usersApi.usersMeRetrieve(),
  });

export const useUserQuery = () => {
  const config = computed(() => userQueryConfig());

  return useQuery(config);
};

export const usersQueryKey = () => ['users'];
export const invalidateUsers = (queryClient: QueryClient) =>
  queryClient.invalidateQueries({ queryKey: usersQueryKey() });

const usersQueryConfig = () =>
  queryOptions({
    queryKey: usersQueryKey(),
    queryFn: () => usersApi.usersList({}),
  });

export const useUsersQuery = () => {
  const config = computed(() => usersQueryConfig());

  return useQuery(config);
};

export const useUpdateUserMutation = (queryClient: QueryClient) =>
  useMutation({
    mutationFn: (data: PatchedUserUpdateRequest) =>
      usersApi.usersMePartialUpdate(data),
    onSuccess: () => {
      invalidateUser(queryClient);
      invalidateUsers(queryClient);
    },
  });

export const killSwitchQueryKey = () => ['kill-switch'];
export const invalidateKillSwitchQuery = (queryClient: QueryClient) =>
  queryClient.invalidateQueries({ queryKey: killSwitchQueryKey() });

const killSwitchQueryConfig = () =>
  queryOptions({
    queryKey: killSwitchQueryKey(),
    queryFn: () => usersApi.usersMeSessionControlStatusRetrieve(),
  });

export const useKillSwitchQuery = () => {
  const config = computed(() => killSwitchQueryConfig());

  return useQuery(config);
};
