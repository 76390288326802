import { OrderListMode } from '~/types';

export default defineNuxtRouteMiddleware((to) => {
  if (to.name === 'index' && Object.keys(to.query).length === 0) {
    return navigateTo({
      name: 'orders',
      query: { managedByUser: 'true', listMode: OrderListMode.List },
    });
  }
  if (to.name === 'orders' && Object.keys(to.query).length === 0) {
    return navigateTo({
      name: 'orders',
      query: { managedByUser: 'true', listMode: OrderListMode.List },
    });
  }
});
